
<template >
    <div class="order-details">
        <Breadcrumb />

        <div class="col-title-order">
            <span class="title-order">{{ orderInfos.order_no }}</span>
        </div>

        <b-col>
            <b-row class="card-detail">
                <b-col md="12">
                    <span class="title-card">{{$t('orderView.title')}}</span>
                    <b-row>
                        <b-col md="3" sm="6">
                            <div class="item-card">
                                <span class="title-item-card">{{$t('orderView.Quantity')}}</span>
                                <span class="info">{{ Math.ceil(orderInfos.planned_quantity) }}</span>
                            </div>
                        </b-col>
                        <b-col md="3" sm="6">
                            <div class="item-card">
                                <span class="title-item-card">{{$t('orderView.Priority')}}</span>
                                <span class="info">{{ Math.ceil(orderInfos.priority)}}</span>
                            </div>
                        </b-col>
                        <b-col md="3" sm="6">
                            <div class="item-card">
                                <span class="title-item-card">{{$t('orderView.EarliestStartDate')}}</span>
                                <span class="info">{{ getFormatedDate(orderInfos.earliest_start_date) }}</span>
                            </div>
                        </b-col>
                        <b-col md="3" sm="6">
                            <div class="item-card">
                                <span class="title-item-card">{{$t('orderView.DueDate')}}</span>
                                <span class="info">{{ getFormatedDate(orderInfos.due_date) }}</span>
                            </div>
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>
            <b-row class="card-detail">
                <b-col md="12">
                    <span class="title-card">{{$t('orderView.OperationInformation')}}</span>
                    <b-row>
                        <b-col md="4" sm="4">
                            <div class="item-card">
                                <span class="title-item-card">{{$t('orderView.OperationNumber')}}</span>
                                <span class="info">{{ orderInfos.op_no }}</span>
                            </div>
                        </b-col>
                        <b-col md="4" sm="4">
                            <div class="item-card">
                                <span class="title-item-card">{{$t('orderView.OperationName2')}}</span>
                                <span class="info">{{ orderInfos.operation_name }}</span>
                            </div>
                        </b-col>
                        <b-col md="4" sm="4">
                            <div class="item-card">
                                <span class="title-item-card">{{$t('orderView.ProductCode')}}</span>
                                <span class="info">{{ orderInfos.part_no }}</span>
                            </div>
                        </b-col>
                        <b-col md="4" sm="4">
                            <div class="item-card">
                                <span class="title-item-card">{{$t('orderView.ProductName')}}</span>
                                <span class="info">{{ orderInfos.product }}</span>
                            </div>
                        </b-col>
                        <b-col md="4" sm="4">
                            <div class="item-card">
                                <span class="title-item-card">{{$t('orderView.Resource')}}</span>
                                <span class="info">{{ orderInfos.resource_name }}</span>
                            </div>
                        </b-col>

                        <b-col md="4" sm="4">
                            <div class="item-card">
                                <span class="title-item-card">{{$t('orderView.Workcenter')}}</span>
                                <span class="info">{{ orderInfos.workcenter }}</span>
                            </div>
                        </b-col>
                        <b-col md="4" sm="4">
                            <div class="item-card">
                                <span class="title-item-card">{{$t('orderView.ForecastOperationSetup')}}</span>
                                <span class="info">{{ getFormatedDate(orderInfos.setup_start) }}</span>
                            </div>
                        </b-col>

                        <b-col md="4" sm="4">
                            <div class="item-card">
                                <span class="title-item-card">{{$t('orderView.ForecastOperationStart')}}</span>
                                <span class="info">{{ getFormatedDate(orderInfos.start_time) }}</span>
                            </div>
                        </b-col>
                        <b-col md="4" sm="4">
                            <div class="item-card">
                                <span class="title-item-card">{{$t('orderView.ForecastOperationEnd')}}</span>
                                <span class="info">{{ getFormatedDate(orderInfos.end_time) }}</span>
                            </div>
                        </b-col>

                    </b-row>
                </b-col>
            </b-row>

            <b-row class="card-detail">
                <b-col md="12">
                    <span class="title-card">{{ $t('OperationProgress.Attributes') }}</span>
                    <b-row>
                        <b-col
                                v-for="(item, index) in orderedAttributes"
                                :key="'custom-attribute-apontamento-' + index"
                                md="4" sm="4"

                            >
                                <div class="item-card">
                                    <span class="title-item-card">{{ item.name }}</span>
                                    <span class="info">{{ item.attr_value }}</span>
                                </div>
                            </b-col>

                    </b-row>
                </b-col>
            </b-row>
            <b-row class="card-detail">
                <b-col md="12">
                    <span class="title-card">{{$t('orderView.Observations')}}</span>
                    <b-row>
                        <b-col md="12">
                            <div class="item-card">
                                <span class="title-item-card">
                                   {{ orderInfos.note }}
                                </span>
                            </div>
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>
            <b-row class="card-detail">
                <b-col md="12" >
                    <span class="title-card links">Links</span>
                    <div class="itens-links">
                        <a target="_blank" @click="openUrl(link.url, link.type)" v-for="(link, index) in linksList" :key="index" class="info card-files-links" >
                            <pdfFile v-if="link.extension == 'pdf'"/>
                            <wordFile v-else-if="['doc','docm','docx','dot','dotx'].includes(link.extension)"/>
                            <mp3File v-else-if="['mp3','m4a'].includes(link.extension)"/>
                            <xmlFile v-else-if="['xla','xlm', 'xls','xlsm','xlsx','xlt','xltm','xltx'].includes(link.extension)"/>
                            <textFile v-else-if="link.extension == 'txt'"/>
                            <mp4File v-else-if="['mp4','mpeg','wmv'].includes(link.extension)"/>
                            <imgFile v-else-if="['png','jpeg','jpg'].includes(link.extension) || link.type == 'base64'"/>
                            <zipFile v-else-if="['zip','rar'].includes(link.extension)"/>
                            <webFile v-else/>
                            <span :id="`tooltip-link-${index}`">{{ link.name }}.{{ link.extension }}</span>
                            <b-tooltip
                                :target="`tooltip-link-${index}`"
                                triggers="hover blur"
                                positioning="top"
                                placement="top"
                                boundary-padding="0"
                                class="tooltip-link"
                            >
                                <div class="info-tooltip-link">
                                    {{link.name}}.{{ link.extension }}
                                </div>
                            </b-tooltip>
                        </a>
                    </div>
                </b-col>
            </b-row>
        </b-col>

    </div>
  </template>

<script>
    import Breadcrumb from '../../../layouts/components/Breadcrumb.vue'
    import pdfFile from "@/assets/images/pages/pdf-file.svg"
    import webFile from "@/assets/images/pages/web-file.svg"
    import wordFile from "@/assets/images/pages/word-file.svg"
    import mp3File from "@/assets/images/pages/mp3-file.svg"
    import xmlFile from "@/assets/images/pages/xml-file.svg"
    import textFile from "@/assets/images/pages/text-file.svg"
    import mp4File from "@/assets/images/pages/mp4-file.svg"
    import imgFile from "@/assets/images/pages/img-file.svg"
    import zipFile from "@/assets/images/pages/zip-file.svg"
  import {
   BRow, BCol,BTooltip
  } from 'bootstrap-vue'
  import { mapMutations } from "vuex";
  import moment from "moment";

  export default {
    components: {

    BRow,
    BCol,
    BTooltip,
    Breadcrumb,
    pdfFile,
    webFile,
    wordFile,
    mp3File,
    xmlFile,
    textFile,
    mp4File,
    imgFile,
    zipFile,
    },

    data() {
      return {
        prefix: localStorage.getItem('prefix') || this.$router.currentRoute.params.prefix,
        currentSite: JSON.parse(localStorage.getItem('currentSite')),
        attributesFields:[],
        linksList:[],
        orderInfos:[],
        orderedAttributes:[]
      }
    },
    created(){
    this.getOrderInfos();
    this.getLinks()
    },
    methods: {
        ...mapMutations('app', [
            'UPDATE_FLAG_SHOW_OVERLAY'
        ]),
        getOrderedAttributes() {
            const tableList = this.attributesFields.filter((item, index) => {
                return item.native_name.split(" ")[0] == "Table";
            });
            tableList.forEach((item, index) => {
                item["attr_value"] = this.orderInfos[`att_${index + 1}`]
                    ? this.orderInfos[`att_${index + 1}`]
                    : "-";
            });
            const stringList = this.attributesFields.filter((item) => {
                return item.native_name.split(" ")[0] == "String";
            });

            stringList.forEach((item, index) => {
                item["attr_value"] = this.orderInfos[`string_attribute_${index + 1}`]
                    ? this.orderInfos[`string_attribute_${index + 1}`]
                    : "-";
            });
            const numericalList = this.attributesFields.filter((item) => {
                return item.native_name.split(" ")[0] == "Numerical";
            });
            numericalList.forEach((item, index) => {
                item["attr_value"] = this.orderInfos[
                    `numerical_attribute_${index + 1}`
                ]
                    ? this.orderInfos[`numerical_attribute_${index + 1}`]
                    : "-";
            });
            const list = [];
            tableList.forEach((item, index) => {
                list.push(
                    tableList[index],
                    stringList[index],
                    numericalList[index]
                );
            });
            this.orderedAttributes = list;
        },
        getAttributesFields() {
            this.$http
                .get(
                    `/api/${this.$router.currentRoute.params.prefix}/attribute-field?site_guid=${this.currentSite.guid}`
                )
                .then((res) => {
                    this.attributesFields = res.data.response;
                    this.getOrderedAttributes()
                    this.UPDATE_FLAG_SHOW_OVERLAY(false);
                });
        },
        openUrl(url, type){
            if(type == 'base64'){
                var win = window.open();
                win.document.write('<iframe src="' + url  + '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>');
            }
            else{
                window.open(url, '_blank')
            }
        },
        getLinks(){
            this.$http.get(`/api/${this.prefix}/order/links?site_guid=${this.currentSite.guid}&preactor_order_id=${this.$router.currentRoute.params.order_id}`)
                .then(res => {
                    this.linksList = res.data
                })
        },
        getFormatedDate(date) {
            if (date) {
                let language = localStorage.getItem('language');

                if (language == 'pt')
                    return moment(date).format('DD-MM-YYYY HH:mm');
                else
                    return moment(date).format('MM-DD-YYYY HH:mm');
            }

            return '';
        },
        getOrderInfos(){
            this.UPDATE_FLAG_SHOW_OVERLAY(true);
            this.$http.get(`/api/${this.prefix}/order/${this.$router.currentRoute.params.order_id}?site_guid=${this.currentSite.guid}`)
                .then(res => {
                    this.orderInfos = res.data.response;
                    this.getAttributesFields()

                })
        },
    }
  }
</script>

<style lang="scss" >

    @media (max-width:480px) {
        .card-detail{
            .item-card{
                flex-direction: row !important;
                justify-content: space-between;
                .title-item-card{
                    font-size: 12px !important;
                    line-height: 16px !important;
                }
                .info{
                    font-size: 14px !important;
                    line-height: 20px !important;
                }
            }
        }
    }

    .order-details{
        .col-title-order{
            margin-bottom: 16px !important;
            .title-order{
                font-weight: 600;
                font-size: 36px;
                line-height: 50px;
                color: #4C4541;
            }
        }

        .card-detail{
            margin-bottom: 24px;
            padding: 16px 2px;
            background: #FFFFFF;
            box-shadow: 0px 4px 24px rgba(58, 46, 38, 0.1);
            border-radius: 6px;

            .title-card{
                font-weight: 600;
                font-size: 18px;
                line-height: 26px;
                color: #4C4541;
            }
            .item-card{
                display: flex;
                flex-direction: column;
                margin-top: 16px;
                .title-item-card{
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 20px;
                    color: #998F8A;
                }
                .info{
                    font-weight: 600;
                    font-size: 22px;
                    line-height: 30px;
                    color: #4C4541;
                }
            }
            .itens-links{
                margin-top: 16px;
                .card-files-links{
                    border: 1px solid #CFC4BE;
                    border-radius: 6px;
                    padding: 24px 16px;
                    margin-bottom: 16px !important;
                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    svg{
                        margin-right: 18px ;
                    }

                    &:hover{
                        cursor: pointer;
                    }
                    span{
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 26px;
                        color: #4C4541;
                    }
                }
            }

        }
    }

</style>
